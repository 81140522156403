<template>
  <div id="news-box" ref="main" class=""> 
    <bannerStatic :num="4" :title="'把握智能制造大势，助力制造业转型升级'"/>
    <div class="news-info minheight500" id="news-info" >
           <a-tabs :animated="false" class="tabstyle" >
        <a-tab-pane  v-for="(item,index) in listData" :key="index+1" :tab="item.moduleName" >
          <a-list itemLayout="vertical" size="large" :pagination="pagination" :dataSource="item.newsList">
            <a-list-item slot="renderItem" slot-scope="item" :key="item.newsID" class="info-box" @click="jump(item.newsID)" >
                  <div class="img-box">
                    <img alt="郑大智能" :src="newsImg+item.newsImageUrl"   />
                  </div>
                  <div class="txt-box">
                    <h3 class="title-box">{{item.newsTitle}}</h3>
                    <p class="content-box">{{item.newsAbstract}}</p>
                    <span class="time-box"><a-icon type="clock-circle" style="margin-right:10px" />{{moment(item.updatedTime).format('YYYY-MM-DD')}}</span>
                  </div>
            </a-list-item> 
          </a-list>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import bannerStatic from'@/components/BannerStatic/BannerStatic'
import { getNews } from '@/api/data'
import  { newsImg } from '@/config/appSetting'
import moment from 'moment'
export default {
  name: 'News',
  components:{
      bannerStatic,
  },
  data() {
      return {
        newsImg,
        listData:[],
        typeList:[],
        pagination: {
          onChange: page => {
            document.documentElement.scrollTop=0
          },
          pageSize: 10,
        },
      };
  },
  methods: {
    moment,
    jump(id){
        this.$router.push({path:`/NewDetails/${id}`})
    },
    getData(id){
      let _this=this
      this.listData=[]
        return getNews({IsDisplay:id,CompanyType:2}).then(res => {
          if (res.state) {
            if(res.data){
              res.data.map((item)=>{
                _this.listData.push(item)
              })
            }
          }
        })
    }
  },  
  mounted() {
      this.getData(0)
  },
}
</script>
<style scoped lang="less">
.news-info {
  width: 1200px;
  margin:0 auto 30px;
  .info-box{
    padding:30px 25px 20px;
    margin-bottom: 15px;
    cursor: pointer;
    overflow: hidden;
    .img-box{
      width: 288px;
      height: 156px;
      float: left;
      overflow: hidden;
      img{
        transition: transform .3s linear;
        width: 100%;
        height: 100%;
        -o-object-position: center;
        object-position: center;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
    .txt-box{
      margin-left: 360px;
      text-align: left;
      height: 156px;
      overflow: hidden;
      .title-box{
        height: 20px;
        line-height: 20px;
        font-size: 20px;
        color: #333;
        margin:10px 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .content-box{
        height: 70px;
        line-height: 25px;
        font-size: 14px;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .time-box{
        font-size: 14px;
        color: #999999;
      }
    }
    &:hover{
      .title-box{
        color: #247de1;
      }
      .img-box{
        img{
          transform: scale(1.03);
        }
      }
    }
  }
}
</style>
<style>
  .news-info .ant-list-pagination {
      text-align: center;
  }
  .news-info .ant-list-vertical .ant-list-item-content{
    margin:0
  }
  .news-info  .ant-empty.ant-empty-normal {
    display: none;
  }
  .news-info  .ant-spin-container {
    min-height: 500px;
  }
  .news-info  .tabstyle{
    margin:30px 0 0;
  }
   .news-info  .ant-tabs-nav .ant-tabs-tab {
    font-size:20px;
    color:#333;
    padding:12px 2px;
  }
 .news-info .ant-list-something-after-last-item .ant-spin-container>.ant-list-item:last-child {
    border-bottom: 0;
  }
</style>